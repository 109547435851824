exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-cabernet-franc-tsx": () => import("./../../../src/pages/cabernet-franc.tsx" /* webpackChunkName: "component---src-pages-cabernet-franc-tsx" */),
  "component---src-pages-cuvee-des-pyrenees-blanc-23-tsx": () => import("./../../../src/pages/cuvee-des-pyrenees-blanc-23.tsx" /* webpackChunkName: "component---src-pages-cuvee-des-pyrenees-blanc-23-tsx" */),
  "component---src-pages-cuvee-des-pyrenees-rouge-21-tsx": () => import("./../../../src/pages/cuvee-des-pyrenees-rouge-21.tsx" /* webpackChunkName: "component---src-pages-cuvee-des-pyrenees-rouge-21-tsx" */),
  "component---src-pages-cuvee-des-pyrenees-rouge-22-tsx": () => import("./../../../src/pages/cuvee-des-pyrenees-rouge-22.tsx" /* webpackChunkName: "component---src-pages-cuvee-des-pyrenees-rouge-22-tsx" */),
  "component---src-pages-cuvee-limoux-21-tsx": () => import("./../../../src/pages/cuvee-limoux-21.tsx" /* webpackChunkName: "component---src-pages-cuvee-limoux-21-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-instant-present-tsx": () => import("./../../../src/pages/instant-present.tsx" /* webpackChunkName: "component---src-pages-instant-present-tsx" */),
  "component---src-pages-oenotourisme-tsx": () => import("./../../../src/pages/oenotourisme.tsx" /* webpackChunkName: "component---src-pages-oenotourisme-tsx" */),
  "component---src-pages-rose-la-martine-tsx": () => import("./../../../src/pages/rose-la-martine.tsx" /* webpackChunkName: "component---src-pages-rose-la-martine-tsx" */),
  "component---src-pages-syrah-des-fous-tsx": () => import("./../../../src/pages/syrah-des-fous.tsx" /* webpackChunkName: "component---src-pages-syrah-des-fous-tsx" */)
}

